import React, { Component } from 'react'
import Particles from 'react-tsparticles'
import Slider from 'react-slick'

import ServiceOne from '../../elements/service/ServiceOne'
import { slideSlick } from '../../page-demo/script'

const SlideList = [
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--1',
    category: '',
    title: 'Comprehensive Mobile App Solutions Firm',
    description:
      'Elevate your brand with custom mobile and web apps designed by our expert team. Turn your vision into reality with our cutting-edge technical expertise.',
    buttonText: 'Contact Us',
    buttonLink: '/contact',
    blackOverlay: '3',
  },
  {
    textPosition: 'text-center',
    bgImage: 'bg_image--1',
    category: '',
    title: 'Diverse Industry Expertise',
    description:
      'Our portfolio showcases successful projects across various industries. Trust us to deliver solutions that meet the unique demands of your sector.',
    buttonText: 'Portfolio',
    buttonLink: '/portfolio',
    blackOverlay: '3',
  },
  // {
  //   textPosition: 'text-left',
  //   bgImage: 'bg_image--1',
  //   category: '',
  //   title: 'Tailored Mobile and Web Solutions',
  //   description:
  //     'We specialize in creating innovative, high-quality mobile apps. Partner with us for digital solutions that drive success.',
  //   buttonText: 'Contact Us',
  //   buttonLink: '/contact',
  //   blackOverlay: '3',
  // },
]

class SliderOne extends Component {
  render() {
    return (
      // <div className="slider-activation">
      //   {/* Start Single Slide */}
      //   <div
      //     className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1"
      //     data-black-overlay="6">
      //     <div className="container position-relative">
      //       <div className="frame-layout__particles">
      //         <Particles className="particle" options={options} />
      //       </div>

      //       <div className="row">
      //         <div className="col-lg-12">
      //           <div className="inner">
      //             <h1 className="title theme-gradient">
      //               Your Technology <br /> Partners.{' '}
      //             </h1>
      //           </div>
      //         </div>
      //       </div>

      //       {/* Start Service Area */}
      //       <div className="service-wrapper service-white">
      //         <ServiceOne />
      //       </div>
      //       {/* End Service Area */}
      //     </div>
      //   </div>
      //   {/* End Single Slide */}
      // </div>

      <div className="slider-wrapper">
        <div className="slider-activation">
          <Slider className="rn-slick-dot dot-light" {...slideSlick}>
            {SlideList.map((value, index) => (
              <div
                className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                key={index}
                data-black-overlay={value?.blackOverlay}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ''}
                        {value.title ? (
                          // This is a fixed height for 2 lines text so in mobile mode the height of slider stay consistent
                          <h2 className="title" style={{ height: 59 }}>
                            {value.title}
                          </h2>
                        ) : (
                          ''
                        )}

                        {value.description ? (
                          <p className="description">{value.description}</p>
                        ) : (
                          ''
                        )}

                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="rn-button-style--2 btn-solid"
                              href={`${value.buttonLink}`}
                              style={{ fontSize: 12 }}>
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  }
}
export default SliderOne

const options = {
  style: { position: 'absolute' },
  fpsLimit: 100,
  interactivity: {
    detectsOn: 'canvas',
    events: {
      onClick: {
        enable: true,
        mode: 'push',
      },
      onHover: {
        enable: true,
        mode: 'repulse',
      },
      resize: true,
    },
    modes: {
      bubble: {
        distance: 100,
        duration: 2,
        opacity: 0.8,
        size: 10,
        color: '#888',
      },
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 100,
        duration: 0.4,
        color: '#888',
      },
    },
  },
  particles: {
    color: {
      value: '#888',
    },
    links: {
      color: '#888',
      distance: 250,
      enable: true,
      opacity: 0.4,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: false,
      speed: 4,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        value_area: 2000,
      },
      value: 80,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: 'circle',
    },
    size: {
      random: true,
      value: 5,
    },
  },
  detectRetina: true,
}
