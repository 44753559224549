import React, { Component, Fragment } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import ScrollAnimation from 'react-animate-on-scroll'

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true })
    }
  }

  render() {
    let Data = [
      {
        countNum: 3,
        countTitle: 'Years Experience',
      },
      {
        countNum: 100,
        countTitle: 'Happy Clients',
      },
      {
        countNum: 250,
        countTitle: 'Solutions Delivered',
      },
    ]

    return (
      <Fragment>
        <div className="row" style={{ flexWrap: 'nowrap' }}>
          {Data.map((value, index) => (
            <div
              className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-4"
              key={index}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOnce={true}
                delay={100}>
                <h5 className="counter">
                  <VisibilitySensor
                    onChange={this.onVisibilityChange}
                    offset={{ top: 10 }}
                    delayedCall>
                    <CountUp
                      end={this.state.didViewCountUp ? value.countNum : 0}
                    />
                  </VisibilitySensor>
                </h5>

                <h4 className="counterup-description">
                  {value.countTitle.split(' ')[0]}
                  <br></br>
                  {value.countTitle.split(' ')[1]}
                </h4>
              </ScrollAnimation>
            </div>
          ))}
        </div>
      </Fragment>
    )
  }
}
export default CounterOne
